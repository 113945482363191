<template>
  <div class="page">
    <el-descriptions :column="2" border>
      <el-descriptions-item>
        <template slot="label" class="labelInfo">
          <span>检查人</span>
        </template>
        <span>{{forms.userName}}</span>
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label" class="labelInfo">
          <span>提交时间</span>
        </template>
        <span>{{forms.createTime}}</span>
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label" class="labelInfo">
          <span>所属单位</span>
        </template>
        <span>{{forms.orgName}}</span>
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label" class="labelInfo">
          <span>类型</span>
        </template>
        <span>{{forms.type== 0? '日管控':(forms.type == 1?'周排查' :'月调度')}}</span>
      </el-descriptions-item>
    </el-descriptions>
    <el-descriptions :column="1" border v-if="type == 1">
      <el-descriptions-item>
        <template slot="label" class="labelInfo">
          <span>本月巡查主要问题</span>
        </template>
        <span>{{forms.thisMonthTrouble}}</span>
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label" class="labelInfo">
          <span>本月整改方案落实情况</span>
        </template>
        <span>{{forms.thisMonthRemediationAction}}</span>
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label" class="labelInfo">
          <span>本月还未解决的问题</span>
        </template>
        <span>{{forms.thisMonthUnresolvedTrouble}}</span>
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label" class="labelInfo">
          <span>月调度相关内容</span>
        </template>
        <div>
          <ul style=" list-style-type:disc;">
            <li v-for="(item,index) in data" :key="index"
              style="list-style:disc;text-align: left;padding: 0px 10px;margin: 10px 0px;">
              <div>
                <div>

                  <span>（{{item.checkItem}}）安全风险可控，无较大（{{item.checkContent}}）安全风险隐患（{{item.checkResult == 0 ? '异常' : '正常'}}）</span>
                </div>
                <div>
                  <span>处置结果：{{item.dealwithResult}}</span>
                </div>
                <div>
                  <span>备注：{{item.comment ? item.comment :'无'}}</span>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label" class="labelInfo">
          <span>其他使用安全事项</span>
        </template>
        <span>{{forms.otherUseSafetyMatters}}</span>
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label" class="labelInfo">
          <span>会议研究采取的措施</span>
        </template>
        <span>{{forms.meetingSummarize}}</span>
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label" class="labelInfo">
          <span>备注</span>
        </template>
        <span>{{forms.comments}}</span>
      </el-descriptions-item>
    </el-descriptions>

    <el-descriptions :column="1" border v-else-if="type == 2">
      <el-descriptions-item>
        <template slot="label">
          <span>上周使用安全风险隐患问题整改核实情况</span>
        </template>
        <span>{{forms.lastWeekSafeRectify}}</span>
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label">
          <span>本周主要使用安全风险隐患和整改情况</span>
        </template>
        <span>{{forms.thisWeekSafeRectify}}</span>
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label">
          <span>本周使用安全管理情况评价</span>
        </template>
        <div>
          <ul style=" list-style-type:disc;">
            <li v-for="(item,index) in data" :key="index" class="liInfo">
              <div>
                <div>
                  <span>（{{item.checkItem}}）安全风险可控，无较大（{{item.checkContent}}）安全风险隐患（{{item.checkResult == 0 ? '异常' : '正常'}}）</span>
                </div>
                <div>
                  <span>处置结果：{{item.dealwithResult}}</span>
                </div>
                <div>
                  <span>备注：{{item.comment ? item.comment :'无'}}</span>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label">
          <span>下周工作重点</span>
        </template>
        <span>{{forms.nextWeekWork}}</span>
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label">
          <span>使用安全管理员意见</span>
        </template>
        <div v-if='forms&&forms.safetyOfficerSuggestion'>
          <div v-for="(item,index) in JSON.parse(forms.safetyOfficerSuggestion)" :key="index" style="margin: 10px 0px;">
            <div>
              <span>{{item.opinion}}</span>
            </div>
            <div style="display: flex;justify-content: space-between;">
              <div>
                <span>{{item.userName}}</span>
              </div>
              <div>
                <span>{{item.time}}</span>
              </div>
            </div>
          </div>
        </div>
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label">
          <span>使用安全总监意见</span>
        </template>
        <div v-if='forms&&forms.safetyGeneralSuggestion'>
          <div v-for="(item,index) in JSON.parse(forms.safetyGeneralSuggestion)" :key="index" style="margin: 10px 0px;">
            <div>
              <span>{{item.opinion}}</span>
            </div>
            <div style="display: flex;justify-content: space-between;">
              <div>
                <span>{{item.userName}}</span>
              </div>
              <div>
                <span>{{item.time}}</span>
              </div>
            </div>
          </div>
        </div>
      </el-descriptions-item>
    </el-descriptions>
    <div v-else>
      <table class="tables">
        <thead>
          <tr>
            <td>
              <span>序号</span>
            </td>
            <td>
              <span>检查项目</span>
            </td>
            <td>
              <span>检查内容</span>
            </td>
            <td>
              <span>检查结果</span>
            </td>
            <td>
              <span>处置结果</span>
            </td>
            <td>
              <span>备注</span>
            </td>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item,index) in Tabledata" :key="index">
            <td>
              <span>{{index+1}}</span>
            </td>
            <td>
              <span>{{item.checkItem}}</span>
            </td>
            <td>
              <span>{{item.checkContent}}</span>
            </td>
            <td>
              <span>{{item.checkResult == 0? '异常':'正常'}}</span>
            </td>
            <td>
              <span>{{item.dealwithResult}}</span>
            </td>
            <td>
              <span>{{item.comment}}</span>
            </td>
          </tr>
        </tbody>
      </table>
      <!-- <template v-if="dictionarTotal != 0"> -->
      <!-- <el-table ref="multipleTable" :data="Tabledata" border style="overflow-x:hidden;width: 100%;">
        <el-table-column type="index" label="序号" width="55px" align="center" />
        <el-table-column label="检查项目" align="center" prop="checkItem" class="constData" />
        <el-table-column label="检查内容" align="center" prop="checkContent" />
        <el-table-column label="检查结果" align="center" prop="checkResult">
          <template slot-scope="scope">{{scope.row.checkResult == 0? '异常':'正常'}}</template>
        </el-table-column>
        <el-table-column label="处置结果" align="center" prop="dealwithResult" />
        <el-table-column label="备注" align="center" prop="comment" />
      </el-table> -->
      <!-- </template> -->
      <div class="footerContent">
        <div style="height: 50px;">
          <span>采取的防范措施：{{forms.countermeasure}}</span>
        </div>
        <div style="float: right;">
          <span>安全员：{{forms.userName}}</span>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
  export default {
    data() {
      return {
        data: [],
        forms: {},
        type: 1,
        dictionarTotal: 0,
        Tabledata: [],
      }
    },
    created() {
      console.log(223, this.$route)
      const data = this.$route.params.id.split(',')
      this.type = data[1]
      console.log(666, data)
      if (data[1] == 1) {
        this.$http.get(`/api/base/web/manage/getMonthManageInfo?id=${data[0]}`).then((res) => {
          console.log(res, 'res')
          if (res.data.code == 200) {
            this.forms = res.data.data
            this.data = JSON.parse(this.forms.riskManageItems)
            setTimeout(() => {
              window.print()
            }, 2000)
          } else {
            this.$message.error(res.data.msg)
          }
        })
      } else if (data[1] == 2) {
        this.$http.get(`/api/base/web/baseWeekCheck/getWeekCheckInfo?id=${data[0]}`).then((res) => {
          console.log(res, 'res')
          if (res.data.code == 200) {
            this.forms = res.data.data
            this.data = JSON.parse(this.forms.riskManageItems)
            setTimeout(() => {
              window.print()
            }, 2000)
          } else {
            this.$message.error(res.data.msg)
          }
        })
      } else {
        this.$http.get(`/api/base/web/getWebDayMCInfoById?id=${data[0]}`).then((res) => {
          console.log(res, 'res')
          if (res.data.code == 200) {
            this.forms = res.data.data
            this.Tabledata = res.data.data.riskManageItems ? JSON.parse(res.data.data.riskManageItems) : [],
              this.dictionarTotal = this.Tabledata.length
            console.log(this.Tabledata, ' this.Tabledata')
            setTimeout(() => {
              window.print()
            }, 2000)
          } else {
            this.$message.error(res.data.msg)
          }
        })
      }
    },
    methods: {

    }
  }
</script>

<style scoped>
  .liInfo {
    list-style: disc;
    text-align: left;
    padding: 0px 10px;
    margin: 10px 0px;
  }

  .tables {
    width: 100%;
    border-collapse: collapse;
  }

  .tables thead tr td {
    border: 1px solid #FFFFFF;
    background: #1282a5;
    color: #FFFFFF;
    height: 50px;
    text-align: center;

  }

  .tables tbody tr td {
    border: 1px solid #FFFFFF;
    /* background: #1282a5; */
    color: #FFFFFF;
    height: 50px;
    text-align: center;

  }

  .footerContent {
    border: 1px solid #FFFFFF;
    height: 70px;
    padding: 20px;
    color: #FFFFFF;
  }

  @media print {

    span {
      color: #000 !important;
    }

    .tables thead tr td {
      border: 1px solid #e7e6e6d1;
      /* background: #1282a5; */
      /* color: #FFFFFF; */
      height: 50px;
      text-align: center;

    }

    .tables tbody tr td {
      border: 1px solid #e7e6e6d1;
      /* background: #1282a5; */
      /* color: #FFFFFF; */
      height: 50px;
      text-align: center;

    }

    .footerContent {
      border: 1px solid #e7e6e6d1;
      height: 70px;
      padding: 20px;
    }
  }
</style>